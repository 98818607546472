define("discourse/plugins/discourse-activity-pub/discourse/routes/activity-pub-actor", ["exports", "@ember/object", "@ember/service", "discourse/routes/discourse", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _object, _service, _discourse, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _discourse.default.extend((_obj = {
    router: (0, _service.inject)(),
    site: (0, _service.inject)(),
    store: (0, _service.inject)(),
    model(params) {
      return _activityPubActor.default.find(params.actor_id);
    },
    setupController(controller, model) {
      const actor = model;
      const props = {
        actor,
        category: null,
        tag: null,
        tags: []
      };
      if (actor.model_type === "category") {
        props.category = this.site.categories.find(c => c.id === actor.model_id);
      }
      if (actor.model_type === "tag") {
        const tag = this.store.createRecord("tag", {
          id: actor.model_name
        });
        props.tag = tag;
        props.tags = [tag.id];
        props.canCreateTopicOnTag = !actor.model.staff || this.currentUser?.staff;
      }
      controller.setProperties(props);
    },
    follow(actor, followActor) {
      return _activityPubActor.default.follow(actor.id, followActor.id).then(result => {
        this.controllerFor(this.router.currentRouteName).actors.unshiftObject(followActor);
        return result;
      });
    },
    unfollow(actor, followedActor) {
      return _activityPubActor.default.unfollow(actor.id, followedActor.id).then(result => {
        this.controllerFor(this.router.currentRouteName).actors.removeObject(followedActor);
        return result;
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "follow", [_object.action], Object.getOwnPropertyDescriptor(_obj, "follow"), _obj), _applyDecoratedDescriptor(_obj, "unfollow", [_object.action], Object.getOwnPropertyDescriptor(_obj, "unfollow"), _obj)), _obj));
});
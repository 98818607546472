define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/utilities", "discourse-common/helpers/d-icon", "discourse-common/lib/later", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _utilities, _dIcon, _later, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _ActivityPubHandle;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ActivityPubHandle = _exports.default = (_class = (_ActivityPubHandle = class ActivityPubHandle extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "copied", _descriptor3, this);
    }
    copy() {
      (0, _utilities.clipboardCopy)(this.args.actor.handle);
      this.copied = true;
      (0, _later.default)(() => {
        this.copied = false;
      }, 2000);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="activity-pub-handle">
        <div class="activity-pub-handle-contents">
          <span class="handle">{{@actor.handle}}</span>
          {{#if @actor.url}}
            <a
              href={{@actor.url}}
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-icon no-text"
            >{{icon "external-link-alt"}}</a>
          {{/if}}
          {{#if this.copied}}
            <DButton @icon="copy" @label="ip_lookup.copied" class="btn-hover" />
          {{else}}
            <DButton @action={{this.copy}} @icon="copy" class="no-text" />
          {{/if}}
        </div>
      </div>
    
  */
  {
    "id": "yVVETTR9",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-handle\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-handle-contents\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"handle\"],[12],[1,[30,1,[\"handle\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"url\"]],[[[1,\"          \"],[10,3],[15,6,[30,1,[\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"btn btn-icon no-text\"],[12],[1,[28,[32,0],[\"external-link-alt\"],null]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"copied\"]],[[[1,\"          \"],[8,[32,1],[[24,0,\"btn-hover\"]],[[\"@icon\",\"@label\"],[\"copy\",\"ip_lookup.copied\"]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,1],[[24,0,\"no-text\"]],[[\"@action\",\"@icon\"],[[30,0,[\"copy\"]],\"copy\"]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@actor\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle.js",
    "scope": () => [_dIcon.default, _dButton.default],
    "isStrictMode": true
  }), _ActivityPubHandle), _ActivityPubHandle), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "copied", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "copy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copy"), _class.prototype)), _class);
});
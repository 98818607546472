define("discourse/plugins/discourse-activity-pub/discourse/controllers/admin-plugins-activity-pub-actor-show", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/models/category", "discourse-common/lib/later", "I18n", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _tracking, _controller, _object, _computed, _service, _category, _later, _I18n, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AdminPluginsActivityPubActorShow = _exports.default = (_dec = (0, _computed.equal)("saveResponse", "success"), (_class = class AdminPluginsActivityPubActorShow extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "site", _descriptor3, this);
      _initializerDefineProperty(this, "categoryId", _descriptor4, this);
      _initializerDefineProperty(this, "tag", _descriptor5, this);
      _initializerDefineProperty(this, "showForm", _descriptor6, this);
      _initializerDefineProperty(this, "enabled", _descriptor7, this);
      _initializerDefineProperty(this, "saving", _descriptor8, this);
      _initializerDefineProperty(this, "saveResponse", _descriptor9, this);
      _defineProperty(this, "modelTypes", [{
        id: "category",
        label: _I18n.default.t("admin.discourse_activity_pub.actor.model_type.category")
      }, {
        id: "tag",
        label: _I18n.default.t("admin.discourse_activity_pub.actor.model_type.tag")
      }]);
      _initializerDefineProperty(this, "saveSuccess", _descriptor10, this);
    }
    get canSave() {
      return this.showForm;
    }
    get containerClass() {
      return `activity-pub-actor-${this.actor.isNew ? "add" : "edit"}`;
    }
    get titleLabel() {
      let key = this.actor.isNew ? "add" : "edit";
      return _I18n.default.t(`admin.discourse_activity_pub.actor.${key}.label`);
    }
    get enabledLabel() {
      let key = this.enabled ? "enabled" : "disabled";
      return `admin.discourse_activity_pub.actor.${key}.label`;
    }
    goBack() {
      this.router.transitionTo("adminPlugins.activityPub.actor");
    }
    saveActor() {
      this.saving = true;
      this.actor.save().then(result => {
        if (result?.success) {
          if (this.actor.isNew) {
            this.saving = false;
            return this.router.transitionTo("adminPlugins.activityPub.actorShow", result.actor);
          }
          this.actor = _activityPubActor.default.create(result.actor);
          this.saveResponse = "success";
        } else {
          this.saveResponse = "failed";
        }
        (0, _later.default)(() => {
          this.saveResponse = null;
        }, 3000);
        this.saving = false;
      });
    }
    toggleEnabled() {
      if (this.enabled) {
        this.actor.disable().then(result => {
          if (result?.success) {
            this.enabled = false;
          }
        });
      } else {
        this.actor.enable().then(result => {
          if (result?.success) {
            this.enabled = true;
          }
        });
      }
    }
    changeCategoryId(categoryId) {
      if (categoryId) {
        this.categoryId = categoryId;
        this.actor.model = _category.default.findById(categoryId);
        this.actor.model_type = "Category";
        this.actor.model_id = categoryId;
        this.showForm = true;
      }
    }
    changeTag(tag) {
      if (tag) {
        this.tag = tag;
        this.actor.model_type = "Tag";
        this.actor.model_name = tag;
        this.showForm = true;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "categoryId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tag", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showForm", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "enabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.actor.enabled;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "saveResponse", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "saveSuccess", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "goBack", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goBack"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveActor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveActor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleEnabled", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeCategoryId", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeCategoryId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeTag", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeTag"), _class.prototype)), _class));
});
define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-authorization", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="activity-pub-authorization">
    <a
      href={{@authorization.actor_id}}
      class="activity-pub-authorization-link btn"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{d-icon "external-link-alt"}}
      {{@authorization.actor_id}}
    </a>
    <DButton
      @icon="times"
      @action={{action "remove"}}
      @title="user.discourse_activity_pub.remove_authorization_button.title"
      id="user_activity_pub_authorize_remove_authorization"
      class="activity-pub-authorize-remove-authorization"
    />
  </div>
  */
  {
    "id": "froer4uV",
    "block": "[[[10,0],[14,0,\"activity-pub-authorization\"],[12],[1,\"\\n  \"],[10,3],[15,6,[30,1,[\"actor_id\"]]],[14,0,\"activity-pub-authorization-link btn\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"external-link-alt\"],null]],[1,\"\\n    \"],[1,[30,1,[\"actor_id\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],[[24,1,\"user_activity_pub_authorize_remove_authorization\"],[24,0,\"activity-pub-authorize-remove-authorization\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[28,[37,2],[[30,0],\"remove\"],null],\"user.discourse_activity_pub.remove_authorization_button.title\"]],null],[1,\"\\n\"],[13]],[\"@authorization\"],false,[\"d-icon\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-authorization.hbs",
    "isStrictMode": false
  });
  let ActivityPubAuthorization = _exports.default = (_class = class ActivityPubAuthorization extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
    }
    remove() {
      const actorId = this.args.authorization.actor_id;
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("user.discourse_activity_pub.authorization.confirm_remove", {
          actor_id: actorId
        }),
        didConfirm: () => {
          this.args.remove(actorId);
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubAuthorization);
});
define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-banner", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "float-kit/components/d-tooltip", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _dTooltip, _activityPubFollowBtn, _activityPubHandle, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ActivityPubBanner;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ActivityPubBanner = _exports.default = (_class = (_ActivityPubBanner = class ActivityPubBanner extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
    get bannerDescription() {
      const visibility1 = this.args.actor.default_visibility;
      const publicationType1 = this.args.actor.publication_type;
      return _discourseI18n.default.t(`discourse_activity_pub.banner.${visibility1}_${publicationType1}`);
    }
    get responsiveText() {
      return _discourseI18n.default.t("discourse_activity_pub.banner.responsive_text");
    }
    get desktopText() {
      return _discourseI18n.default.t("discourse_activity_pub.banner.text", {
        model_name: this.args.actor.model.name
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="activity-pub-banner">
        {{#if @actor}}
          <div class="activity-pub-banner-left activity-pub-banner-side">
            <DTooltip
              @icon="discourse-activity-pub"
              @content={{this.bannerDescription}}
            />
            <div class="activity-pub-banner-text">
              <span class="desktop">{{this.desktopText}}</span>
              <span class="responsive">{{this.responsiveText}}</span>
            </div>
          </div>
          <div class="activity-pub-banner-right activity-pub-banner-side">
            {{#unless this.site.mobileView}}
              <ActivityPubHandle @actor={{@actor}} />
            {{/unless}}
            <ActivityPubFollowBtn @actor={{@actor}} @type="follow" />
          </div>
        {{/if}}
      </div>
    
  */
  {
    "id": "kuP3hUXw",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-banner\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[10,0],[14,0,\"activity-pub-banner-left activity-pub-banner-side\"],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@icon\",\"@content\"],[\"discourse-activity-pub\",[30,0,[\"bannerDescription\"]]]],null],[1,\"\\n          \"],[10,0],[14,0,\"activity-pub-banner-text\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"desktop\"],[12],[1,[30,0,[\"desktopText\"]]],[13],[1,\"\\n            \"],[10,1],[14,0,\"responsive\"],[12],[1,[30,0,[\"responsiveText\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"activity-pub-banner-right activity-pub-banner-side\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"site\",\"mobileView\"]]],[[[1,\"            \"],[8,[32,1],null,[[\"@actor\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[8,[32,2],null,[[\"@actor\",\"@type\"],[[30,1],\"follow\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@actor\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-banner.js",
    "scope": () => [_dTooltip.default, _activityPubHandle.default, _activityPubFollowBtn.default],
    "isStrictMode": true
  }), _ActivityPubBanner), _ActivityPubBanner), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});
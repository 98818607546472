define("discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-actor-unfollow", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @title={{this.title}}
    class="activity-pub-actor-unfollow-modal"
  >
    <:body>
      <div class="activity-pub-actor-unfollow">
        {{i18n
          "discourse_activity_pub.actor_unfollow.confirm"
          actor=@model.actor.name
          followed_actor=@model.followedActor.handle
        }}
      </div>
    </:body>
  
    <:footer>
      <DButton
        @action={{action "unfollow"}}
        @label="discourse_activity_pub.actor_unfollow.label"
        class="btn-primary"
      />
      <DModalCancel @close={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "zJTIn7/X",
    "block": "[[[8,[39,0],[[24,0,\"activity-pub-actor-unfollow-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[30,0,[\"title\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-actor-unfollow\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"discourse_activity_pub.actor_unfollow.confirm\"],[[\"actor\",\"followed_actor\"],[[30,2,[\"actor\",\"name\"]],[30,2,[\"followedActor\",\"handle\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,3],[[30,0],\"unfollow\"],null],\"discourse_activity_pub.actor_unfollow.label\"]],null],[1,\"\\n    \"],[8,[39,4],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"d-button\",\"action\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-actor-unfollow.hbs",
    "isStrictMode": false
  });
  let ActivityPubActorUnfollow = _exports.default = (_class = class ActivityPubActorUnfollow extends _component2.default {
    get title() {
      return _I18n.default.t("discourse_activity_pub.actor_unfollow.modal_title", {
        actor: this.args.model.actor.name
      });
    }
    unfollow() {
      const model = this.args.model;
      model.unfollow(model.actor, model.followedActor);
      this.args.closeModal();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "unfollow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unfollow"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubActorUnfollow);
});
define("discourse/plugins/discourse-activity-pub/discourse/routes/activity-pub-actor-follows", ["exports", "@ember/array", "discourse/routes/discourse", "discourse-common/utils/decorators", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _array, _discourse, _decorators, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _discourse.default.extend((_obj = {
    queryParams: {
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      }
    },
    afterModel(_, transition) {
      const actor = this.modelFor("activityPub.actor");
      if (!actor.can_admin) {
        this.router.replaceWith("/404");
        return;
      }
      return _activityPubActor.default.list(actor.id, transition.to.queryParams, "follows").then(response => this.setProperties(response));
    },
    setupController(controller) {
      controller.setProperties({
        actor: this.modelFor("activityPub.actor"),
        actors: (0, _array.A)(this.actors || []),
        loadMoreUrl: this.meta?.load_more_url,
        total: this.meta?.total
      });
    },
    activate() {
      this.messageBus.subscribe("/activity-pub", this.handleMessage);
    },
    deactivate() {
      this.messageBus.unsubscribe("/activity-pub", this.handleMessage);
    },
    handleMessage(data) {
      const model = data.model;
      const actor = this.modelFor("activityPub.actor");
      if (model && model.type === "category" && model.id === actor.id) {
        this.refresh();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "handleMessage", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "handleMessage"), _obj)), _obj));
});
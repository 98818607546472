define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-status", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/string", "discourse-common/helpers/d-icon", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _string, _dIcon, _decorators, _I18n, _activityPubActor, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _ActivityPubStatus;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ActivityPubStatus = _exports.default = (_class = (_ActivityPubStatus = class ActivityPubStatus extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      _initializerDefineProperty(this, "messageBus", _descriptor3, this);
      _initializerDefineProperty(this, "forComposer", _descriptor4, this);
      _initializerDefineProperty(this, "ready", _descriptor5, this);
      _initializerDefineProperty(this, "enabled", _descriptor6, this);
      this.forComposer = this.args.modelType === "composer";
      const actor1 = this.findActor();
      if (actor1) {
        this.ready = actor1.ready;
        this.enabled = actor1.enabled;
        this.messageBus.subscribe("/activity-pub", this.handleMessage);
        if (this.forComposer && !this.args.model.activity_pub_visibility) {
          this.args.model.activity_pub_visibility = actor1.default_visibility;
        }
      }
    }
    findActor() {
      const category1 = this.forComposer ? this.args.model.category : this.args.model;
      const tags1 = this.forComposer ? this.args.model.tags : [this.args.model];
      let actor1;
      if (category1) {
        actor1 = _activityPubActor.default.findByModel(category1, "category");
      }
      if (!actor1 && tags1) {
        tags1.some(tag1 => {
          if (tag1) {
            actor1 = _activityPubActor.default.findByModel(tag1, "tag");
          }
          return !!actor1;
        });
      }
      return actor1;
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.messageBus.unsubscribe("/activity-pub", this.handleMessage);
    }
    handleMessage(data1) {
      const model1 = data1.model;
      if (model1 && model1.type === this.args.modelType && model1.id === this.args.model.id) {
        this.enabled = model1.enabled;
        this.ready = model1.ready;
      }
    }
    get active() {
      return this.site.activity_pub_enabled && this.enabled && this.ready;
    }
    get translatedTitle() {
      const args1 = {
        model_type: this.args.modelType
      };
      if (this.active) {
        args1.delay_minutes = this.siteSettings.activity_pub_delivery_delay_minutes;
      }
      return _I18n.default.t(`discourse_activity_pub.status.title.${this.translatedTitleKey}`, args1);
    }
    get translatedTitleKey() {
      if (!this.site.activity_pub_enabled) {
        return "plugin_disabled";
      }
      if (this.args.modelType === "category" && this.args.model.read_restricted) {
        return "category_read_restricted";
      }
      if (!this.enabled) {
        return "model_disabled";
      }
      if (!this.ready) {
        return "model_not_ready";
      }
      if (this.active) {
        if (!this.site.activity_pub_publishing_enabled) {
          return "publishing_disabled";
        }
        return "model_active.first_post";
      } else {
        return "model_not_active";
      }
    }
    get statusKey() {
      if (this.active) {
        return !this.site.activity_pub_publishing_enabled ? "publishing_disabled" : "active";
      } else {
        return "not_active";
      }
    }
    get classes() {
      let result1 = `activity-pub-status ${(0, _string.dasherize)(this.statusKey)}`;
      if (this.args.onClick) {
        result1 += " clickable";
      }
      return result1;
    }
    labelKey(type1) {
      let attribute1 = "status";
      let key1 = this.statusKey;
      if (this.forComposer && this.site.activity_pub_publishing_enabled) {
        attribute1 = "visibility";
        key1 = this.args.model.activity_pub_visibility;
      }
      return `discourse_activity_pub.${attribute1}.${type1}.${key1}`;
    }
    get translatedLabel() {
      return _I18n.default.t(this.labelKey("label"));
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class={{this.classes}} title={{this.translatedTitle}} role="button">
        {{icon "discourse-activity-pub"}}
        <span class="label">{{this.translatedLabel}}</span>
      </div>
    
  */
  {
    "id": "kaVE9ka/",
    "block": "[[[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"classes\"]]],[15,\"title\",[30,0,[\"translatedTitle\"]]],[14,\"role\",\"button\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse-activity-pub\"],null]],[1,\"\\n      \"],[10,1],[14,0,\"label\"],[12],[1,[30,0,[\"translatedLabel\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-status.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), _ActivityPubStatus), _ActivityPubStatus), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "messageBus", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "forComposer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ready", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "enabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleMessage", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleMessage"), _class.prototype)), _class);
});
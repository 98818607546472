define("discourse/plugins/discourse-activity-pub/discourse/controllers/activity-pub-actor-followers", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _tracking, _controller, _object, _computed, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ActivityPubActorFollowers = _exports.default = (_dec = (0, _computed.notEmpty)("actors"), (_class = class ActivityPubActorFollowers extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "order", _descriptor, this);
      _initializerDefineProperty(this, "asc", _descriptor2, this);
      _defineProperty(this, "queryParams", ["order", "asc"]);
      _initializerDefineProperty(this, "hasActors", _descriptor3, this);
    }
    loadMore() {
      if (!this.loadMoreUrl || this.total <= this.actors.length) {
        return;
      }
      this.set("loadingMore", true);
      return (0, _ajax.ajax)(this.loadMoreUrl).then(response => {
        if (response) {
          this.follows.pushObjects(response.actors);
          this.setProperties({
            loadMoreUrl: response.meta.load_more_url,
            loadingMore: false
          });
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "order", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "asc", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasActors", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype)), _class));
});
define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/string", "discourse/components/d-button", "I18n", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-actor-follow", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-actor-unfollow", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-follow", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _string, _dButton, _I18n, _activityPubActorFollow, _activityPubActorUnfollow, _activityPubFollow, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ActivityPubFollowBtn;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const modalMap = {
    follow: _activityPubFollow.default,
    actor_follow: _activityPubActorFollow.default,
    actor_unfollow: _activityPubActorUnfollow.default
  };
  let ActivityPubFollowBtn = _exports.default = (_class = (_ActivityPubFollowBtn = class ActivityPubFollowBtn extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
    }
    showModal() {
      this.modal.show(modalMap[this.args.type], {
        model: this.args
      });
    }
    get class() {
      return `activity-pub-${(0, _string.dasherize)(this.args.type)}-btn`;
    }
    get label() {
      return _I18n.default.t(`discourse_activity_pub.${this.args.type}.label`);
    }
    get title() {
      return _I18n.default.t(`discourse_activity_pub.${this.args.type}.title`, {
        actor: this.args.actor?.name
      });
    }
    get icon() {
      switch (this.args.type) {
        case "follow":
          return "external-link-alt";
        case "actor_follow":
          return "plus";
        case "actor_unfollow":
          return "";
        default:
          return "";
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DButton
        @icon={{this.icon}}
        @action={{this.showModal}}
        @translatedLabel={{this.label}}
        @translatedTitle={{this.title}}
        class={{this.class}}
      />
    
  */
  {
    "id": "rOfRwmR8",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[30,0,[\"class\"]]]],[[\"@icon\",\"@action\",\"@translatedLabel\",\"@translatedTitle\"],[[30,0,[\"icon\"]],[30,0,[\"showModal\"]],[30,0,[\"label\"]],[30,0,[\"title\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _ActivityPubFollowBtn), _ActivityPubFollowBtn), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showModal"), _class.prototype)), _class);
});
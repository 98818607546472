define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-nav-item", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _service, _getUrl, _decorators, _activityPubActor, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @model}}
    <a
      class={{this.classes}}
      href={{this.href}}
      title={{i18n
        "discourse_activity_pub.discovery.description"
        model_name=@model.name
      }}
      {{did-insert this.subscribe}}
      {{did-insert this.didChangeModel}}
      {{did-update this.didChangeModel @model}}
      {{will-destroy this.unsubscribe}}
    >
      {{d-icon "discourse-activity-pub"}}
      {{i18n "discourse_activity_pub.discovery.label"}}
    </a>
  {{/if}}
  */
  {
    "id": "MRfWyABX",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,3],[16,0,[30,0,[\"classes\"]]],[16,6,[30,0,[\"href\"]]],[16,\"title\",[28,[37,1],[\"discourse_activity_pub.discovery.description\"],[[\"model_name\"],[[30,1,[\"name\"]]]]]],[4,[38,2],[[30,0,[\"subscribe\"]]],null],[4,[38,2],[[30,0,[\"didChangeModel\"]]],null],[4,[38,3],[[30,0,[\"didChangeModel\"]],[30,1]],null],[4,[38,4],[[30,0,[\"unsubscribe\"]]],null],[12],[1,\"\\n    \"],[1,[28,[35,5],[\"discourse-activity-pub\"],null]],[1,\"\\n    \"],[1,[28,[35,1],[\"discourse_activity_pub.discovery.label\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@model\"],false,[\"if\",\"i18n\",\"did-insert\",\"did-update\",\"will-destroy\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-nav-item.hbs",
    "isStrictMode": false
  });
  let ActivityPubNavItem = _exports.default = (_class = class ActivityPubNavItem extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "messageBus", _descriptor2, this);
      _initializerDefineProperty(this, "site", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "visible", _descriptor5, this);
      _initializerDefineProperty(this, "actor", _descriptor6, this);
    }
    subscribe() {
      this.messageBus.subscribe("/activity-pub", this.handleActivityPubMessage);
    }
    unsubscribe() {
      this.messageBus.unsubscribe("/activity-pub", this.handleActivityPubMessage);
    }
    didChangeModel() {
      const actor = _activityPubActor.default.findByModel(this.args.model, this.args.modelType);
      if (actor && this.canAccess(actor)) {
        this.actor = actor;
        this.visible = true;
      }
    }
    canAccess(actor) {
      return this.site.activity_pub_publishing_enabled || actor.can_admin;
    }
    handleActivityPubMessage(data) {
      if (_activityPubActor.actorModels.includes(data.model.type) && this.args.model && data.model.id.toString() === this.args.model.id.toString()) {
        this.visible = data.model.ready;
      }
    }
    get classes() {
      let result = "activity-pub-route-nav";
      if (this.visible) {
        result += " visible";
      }
      if (this.active) {
        result += " active";
      }
      return result;
    }
    get href() {
      if (!this.actor) {
        return;
      }
      const path = this.site.activity_pub_publishing_enabled ? "followers" : "follows";
      return (0, _getUrl.default)(`${_activityPubActor.actorClientPath}/${this.actor.id}/${path}`);
    }
    get active() {
      return this.router.currentRouteName.includes(`activityPub.actor`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "messageBus", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "visible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "actor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "subscribe", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "subscribe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unsubscribe", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "unsubscribe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didChangeModel", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "didChangeModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleActivityPubMessage", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleActivityPubMessage"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubNavItem);
});
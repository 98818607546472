define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-follow-btn", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/d-button", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _dButton, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _ActivityPubActorFollowBtn;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ActivityPubActorFollowBtn = _exports.default = (_class = (_ActivityPubActorFollowBtn = class ActivityPubActorFollowBtn extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "followed", _descriptor, this);
      _initializerDefineProperty(this, "following", _descriptor2, this);
      _initializerDefineProperty(this, "followRequested", _descriptor3, this);
    }
    follow() {
      if (this.followed || this.followRequested) {
        return;
      }
      this.following = true;
      this.args.follow(this.args.actor, this.args.followActor).then(result1 => {
        this.followRequested = result1;
        this.following = false;
      });
    }
    get icon() {
      if (this.followed) {
        return "user-check";
      } else if (this.followRequested) {
        return null;
      } else {
        return "user-plus";
      }
    }
    get i18nKey() {
      if (this.followed) {
        return "following";
      } else if (this.followRequested) {
        return "follow_requested";
      } else {
        return "follow";
      }
    }
    get title() {
      const opts1 = {
        actor: this.args.actor.username,
        follow_actor: this.args.followActor.username
      };
      return _discourseI18n.default.t(`discourse_activity_pub.actor_follow.${this.i18nKey}.title`, opts1);
    }
    get label() {
      return _discourseI18n.default.t(`discourse_activity_pub.actor_follow.${this.i18nKey}.label`);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DButton
        @action={{this.follow}}
        @icon={{this.icon}}
        @translatedLabel={{this.label}}
        @translatedTitle={{this.title}}
        @disabled={{this.following}}
        class="activity-pub-follow-actor-btn"
      />
    
  */
  {
    "id": "tI0cOAwF",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"activity-pub-follow-actor-btn\"]],[[\"@action\",\"@icon\",\"@translatedLabel\",\"@translatedTitle\",\"@disabled\"],[[30,0,[\"follow\"]],[30,0,[\"icon\"]],[30,0,[\"label\"]],[30,0,[\"title\"]],[30,0,[\"following\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-follow-btn.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _ActivityPubActorFollowBtn), _ActivityPubActorFollowBtn), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "followed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return !!this.args.followActor.followed_at;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "following", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "followRequested", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "follow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "follow"), _class.prototype)), _class);
});
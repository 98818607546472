define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-model", ["exports", "@glimmer/component", "@ember/object/computed", "discourse/helpers/category-link", "discourse/helpers/discourse-tag", "@ember/component", "@ember/template-factory"], function (_exports, _component, _computed, _categoryLink, _discourseTag, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _ActivityPubActorModel;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ActivityPubActorModel = _exports.default = (_dec = (0, _computed.equal)("args.actor.model_type", "category"), _dec2 = (0, _computed.equal)("args.actor.model_type", "tag"), (_class = (_ActivityPubActorModel = class ActivityPubActorModel extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isCategory", _descriptor, this);
      _initializerDefineProperty(this, "isTag", _descriptor2, this);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="activity-pub-actor-model">
        {{#if this.isCategory}}
          {{categoryLink @actor.model}}
        {{/if}}
        {{#if this.isTag}}
          {{discourseTag @actor.model.name}}
        {{/if}}
      </div>
    
  */
  {
    "id": "ZAXLVDf2",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-actor-model\"],[12],[1,\"\\n\"],[41,[30,0,[\"isCategory\"]],[[[1,\"        \"],[1,[28,[32,0],[[30,1,[\"model\"]]],null]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"isTag\"]],[[[1,\"        \"],[1,[28,[32,1],[[30,1,[\"model\",\"name\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@actor\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-model.js",
    "scope": () => [_categoryLink.default, _discourseTag.default],
    "isStrictMode": true
  }), _ActivityPubActorModel), _ActivityPubActorModel), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isCategory", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isTag", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});